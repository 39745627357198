@import '../../assets/design/colors';

.boxRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}

.headerTypo {
  font-size: medium;
  font-weight: bold;
  color: $primary-color;
  margin: 1em;
}
.btn {
  margin-left: auto;
  margin-right: 0px;
  height: 3em;
}
.DialogBox {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.formBox {
  padding: 2em;
}
.modalBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: justify;
}
.btnBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 1em 0 1em;
}
.modalBtn {
  margin: 2em 0.5em 0;
}
