@import '../../assets/design/colors';

%display-col-div {
  width: 100%;
  display: flex;
  flex-direction: column;
}

%display-row-div {
  display: flex;
  flex-direction: row;
}

.modalBox {
  @extend %display-col-div;
  text-align: justify;
}

.modalBtn {
  margin-top: 2em;
}

.culomnFieldSet {
  flex: 1;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  border: solid thin rgba($primary-color, 0.5);
}

.legendFieldSet {
  font-size: small;
  font-weight: bold;
  color: $primary-color;
}

.boxRowDisplay {
  @extend %display-row-div;
  flex: 1;
  flex-wrap: wrap;
}
.boxRowDisplayNoWrap {
  @extend %display-row-div;
  flex: 1;
  flex-wrap: nowrap;
}

.boxColDisplay {
  @extend %display-col-div;
  height: 90%;
  // margin: 3em;
  display: block;
  overflow: auto;
  padding: 3em;
  // padding-bottom: 1em;
}

.boxDetail {
  @extend %display-col-div;
  position: relative;
  padding: 30px;
  background-color: white;
  border: none;
  border-radius: 10px;
  box-shadow: 1px 1px 10px;
  margin-bottom: 1em;
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
}

.iconFont {
  color: rgba($primary-color, 1);
}
.labelFont {
  font-weight: bold;
  margin-right: 0.5em;
}
.btn {
  min-width: 100px;
  margin: 0.1em;
}
.boxBtn {
  @extend %display-row-div;
  flex: 1;
  justify-content: flex-end;
}
