@import '../../assets/design/colors';

.multiStepFormDiv {
  position: relative;
  width: 90%;
  height: 85%;
  padding: 20px 30px 1em;
  max-width: 900px;
  background-color: white;
  border: none;
  border-radius: 10px;
  box-shadow: 1px 1px 10px;
  display: block;
  padding-bottom: 1em;
  @media screen and (max-width: 768px) {
    height: 100%;
    width: 95%;
    overflow: hidden;
    padding: 5px;
  }
}

.formDiv {
  width: 100%;
  min-height: 80%;
  height: 100%;
}

.confirmFormDiv {
  width: 100%;
  min-height: 80%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 2px;
}
.rowFieldSet {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.culomnFieldSet {
  flex: 1;
  border: solid thin rgba(0, 84, 133, 0.5);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  padding-bottom: 2em;
  height: 100%;
  overflow: auto;
}
.culomnFieldSetNoBorder {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  padding-bottom: 2em;
}

.fieldDiv {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 20px;
}
.addressFieldDiv {
  display: flex;
  flex-direction: column;
  flex: 2;
  margin-right: 20px;
}
.linkTypography {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 10px;
}

.customIconButton {
  margin-right: 10px;
  margin-left: auto;
  background-color: rgba(0, 84, 133, 0.1);
  color: $primary-color;
}

.rowOrderedBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
}
.modalBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: justify;
}

.btnBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 1em 0 1em;
}
.modalBtn {
  margin: 2em 0.5em 0;
}
/* .modalBtn {
  margin: 0 1em 0 1em;
} */

.socialInputIcon {
  color: rgba(0, 84, 133, 1);
  margin-right: 10px;
}

.socialIcon {
  color: rgba(0, 84, 133, 1);
  margin-right: 5px;
  transform: translateY(5px);
}
.rowCenterAlignedFieldSet {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1em;
}

.goToStepLink {
  position: absolute;
  right: 0;
  top: 0;
  background-color: $success-color;
  color: white;
  height: 25px;
  &:hover {
    background-color: $success-color-dark;
  }
}

.confirmFormRow {
  margin-top: 0.7em;
}
