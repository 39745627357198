@import '../../../assets/design/colors';

%display-col-div {
  display: flex;
  flex-direction: column;
}

%display-col-div-centered {
  @extend %display-col-div;
  align-items: center;
  justify-content: center;
}

%display-row-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.mainBody {
  @extend %display-col-div;
  width: 100%;
  height: 100%;
  // margin-right: 1em;
  // margin-left: auto;
  background-color: whitesmoke;
}
.rowOrderedBox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.colOrderedBox {
  display: flex;
  flex-direction: column;

  flex: 1;
}
.culomnFieldSet {
  width: 100%;
  border-radius: 5px;
  margin-bottom: 1em;
  border: solid thin rgba($primary-color, 0.5);
}

.activeOrdersFieldSet {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  margin-bottom: 1em;
  border: solid thin rgba($primary-color, 0.5);
}
.legendFieldSet {
  font-size: small;
  font-weight: bold;
  color: $primary-color;
}

.rowFieldSet {
  @extend %display-row-div;
  width: 100%;
  align-items: flex-end;
}

.fieldDiv {
  @extend %display-col-div;
  flex: 1;
  margin-right: 20px;
}

.typoTip {
  font-size: small;
  color: $primary-color;
  margin-bottom: 1em;
  margin-top: 1em;
}

.customIconButton {
  margin-left: 0.5em;
  background-color: rgba($primary-color, 0.1);
  color: $primary-color;
}

.btnBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 2em;
}

.btn {
  margin-left: auto;
  margin-right: 0px;
  height: 3em;
}

.rowBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.modalBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: justify;
}

.form {
  padding: 1em 2em;
  max-width: 900px;
}

.orderItemBox {
  width: 90%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}

.orderItemName {
  flex: 3;
}
.orderItemCount {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
}
// .DialogBox {
//   display: flex;
//   width: 100%;
//   flex-direction: column;
// }

.dialogBtnBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.dialogBtn {
  min-width: 120px;
  margin-left: 0.5em;
}
