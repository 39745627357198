%flexRowBox {
  width: 100%;
  display: flex;
  flex-direction: row;
}

%flexColBox {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.btnBox {
  @extend %flexRowBox;
  justify-content: flex-end;
  margin-top: 1em;
}

.modalBox {
  @extend %flexColBox;
  text-align: justify;
}
.modalBtn {
  margin: 2em 1em 0;
}
