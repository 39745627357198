@import '../../assets/design/colors';

.headerTypo {
  font-size: medium;
  font-weight: bold;
  color: $primary-color;
  margin: 1em;
}

.modalBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: justify;
}

.btnBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1em;
}
.modalBtn {
  margin: 2em 1em 0;
}
.DialogBox {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.approveBtn {
  margin: 2em 1em 0;
  background-color: green;
  color: black;
  border-color: black;
}
.rejectBtn {
  margin: 2em 1em 0;
  background-color: rgba($color: red, $alpha: 0.8);
}
.rejectBtn:hover {
  background-color: rgba($color: red, $alpha: 0.7);
}
