@import '../../assets/design/colors';

%culomnFieldSet {
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}

.activeCulomnFieldSet {
  @extend %culomnFieldSet;
  border: solid thin rgba(0, 84, 133, 0.5);
}

.inactiveCulomnFieldSet {
  @extend %culomnFieldSet;
  border: solid thin gray;
  display: none;
}

.stepTitle {
  font-size: large;
  font-weight: 700;
  text-align: center;
}

.stepDescription {
  text-align: center;
  font-size: small;
  font-weight: 400;
  color: rgb(22, 22, 22);
}

.multiStepFormDiv {
  position: relative;
  width: 90%;
  height: 85%;
  max-width: 900px;
  padding: 20px 30px 1em;
  background-color: white;
  border: none;
  border-radius: 10px;
  box-shadow: 1px 1px 10px;
  display: block;
  padding-bottom: 1em;
  @media screen and (max-width: 768px) {
    width: 95%;
    overflow: hidden;
    padding: 10px 5px;
  }
}
.containerDiv {
  width: 100%;
  height: 100%;
}
.formDiv {
  width: 100%;
  min-height: 80%;
  height: 100%;
}
.confirmFormDiv {
  width: 100%;
  min-height: 80%;
  height: 100%;
  padding: 0 2px;
  overflow-x: hidden;
  overflow-y: auto;
}
.legendFieldSet {
  font-size: small;
  font-weight: bold;
  color: #005485;
}
.switchLabel {
  font-size: small;
  font-weight: bold;
  color: #005485;
  margin: 0 0.5em 0.5em 1em;
}
.legendFieldSetDisabled {
  font-size: small;
  font-weight: bold;
  color: gray;
}
.rowFieldSet {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.rowCenterAlignedFieldSet {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.fieldDiv {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 20px;
}
.addressFieldDiv {
  display: flex;
  flex-direction: column;
  flex: 2;
  margin-right: 20px;
}
.iconButtonDiv {
  flex: 0.1;
}
.customIconButton {
  // margin-right: 10px;
  margin-left: auto;
  background-color: rgba(0, 84, 133, 0.1);
  color: rgba(0, 84, 133, 1);
}

.input {
  flex: 1;
  padding-right: 20px;
}
.modalBtn {
  margin: 2em 1em 0;
}

.socialInputIcon {
  color: rgba(0, 84, 133, 1);
  margin-right: 10px;
}

.socialIcon {
  color: rgba(0, 84, 133, 1);
  margin-right: 5px;
  transform: translateY(5px);
}

.orderItemBox {
  width: 90%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}

.orderItemName {
  flex: 3;
}
.orderItemCount {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.minusIconButton {
  // background-color: rgba(255, 0, 0, 0.1);
  color: rgb(255, 0, 0);
}

.linkTypography {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 10px;
}

.goToStepLink {
  position: absolute;
  right: 0;
  top: 0;
  background-color: $success-color;
  color: white;
  height: 25px;
  &:hover {
    background-color: $success-color-dark;
  }
}
