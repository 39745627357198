.form {
  width: 100%;
  max-width: 900px;
  padding: 2em;
}

.formBtn {
  min-width: 90px;
  margin-left: 0.5em;
}

.modalBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: justify;
}
.btnBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 1em 0 1em;
}
.modalBtn {
  margin: 2em 0.5em 0;
}
