@import '../../assets/design/colors';

.bodyDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: relative;
}
.imgBox {
  touch-action: none;
  width: 100%;
  height: 100vh;
  position: relative;
  background-image: url('../../assets/img/SHL-Event-2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(3px);
  z-index: 1;
  /* background-color: rgba(0, 84, 133, 0.1); */
  /* rgb(240, 240, 240); */
}
.headerBox {
  position: fixed;
  top: 0;
  height: 20px;
  width: 100%;
  background-color: $primary-color;
  z-index: 2;
}
.logo {
  width: 100px;
  height: 100px;
  position: fixed;
  left: 50px;
  top: 0;
  z-index: 30;
}
.contentDiv {
  touch-action: none;
  position: fixed;
  top: 105px;
  width: 100%;
  // height: calc(100% - 50px);
  height: calc(100vh - 140px);
  display: flex;
  // align-items: center;
  justify-content: center;
  // background-color: $secondary-color;
  // opacity: 0.9;
  z-index: 2;
}
.footerBox {
  position: fixed;
  bottom: 0;
  height: 20px;
  width: 100%;
  background-color: $primary-color;
  z-index: 2;
}
.selectLangBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100px;
  background-color: rgba(0, 84, 133, 0);
}

.flagStyle {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
