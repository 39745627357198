@import '../../assets/design/colors';

.culomnFieldSet {
  width: 100%;
  max-height: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  border: none;
  overflow: auto;
  min-inline-size: auto;
}

.confirmFieldSet {
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  border: solid thin;
  border-color: rgba($primary-color, 0.5);
}

.legendFieldSet {
  font-size: small;
  font-weight: bold;
  color: black;
}
