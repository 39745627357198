@import '../../assets/design/colors';

.formDiv {
  max-width: 900px;
}

.socialIcon {
  color: $primary-color;
  //   margin-right: 10px;
}
.submitBtn {
  margin-left: auto;
  margin-right: 1em;
}

.modalBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: justify;
}
.btnBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.modalBtn {
  margin: 2em 1em 0;
}
