.createModalBox {
  width: 100%;
}

.form {
  max-width: 900px;
}

.btnBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 0.75rem;
}

.modalBtn {
  width: 110px;
}
