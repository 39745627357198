@import '../../assets/design/colors';

.multiStepFormDiv {
  position: relative;
  width: 90%;
  height: 85%;
  padding: 20px 30px 1em;
  max-width: 900px;
  background-color: white;
  border: none;
  border-radius: 10px;
  box-shadow: 1px 1px 10px;
  display: block;
  padding-bottom: 1em;
  @media screen and (max-width: 768px) {
    height: 100%;
    width: 95%;
    overflow: hidden;
    padding: 5px;
  }
}

.mainDiv {
  width: 100%;
  height: 100%;
}

.colOrderedBox {
  display: flex;
  flex-direction: column;
}
.formDiv {
  width: 100%;
  min-height: 80%;
  height: 100%;
}
.customIconButton {
  margin-right: 10px;
  margin-left: auto;
  background-color: rgba($primary-color, 0.1);
  color: $primary-color;
}
.linkTypo {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 5px;
}
.doubledFormField {
  display: flex;
  flex-direction: column;
  flex: 2;
  margin-right: 20px;
}

.formFieldRow {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-right: 20px;
  margin-top: 1em;
  align-items: center;
}

.socialIcon {
  color: $primary-color;
  margin-right: 10px;
}

.modalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  background-color: whitesmoke;
  border: solid thin;
  border-color: $primary-color;
  border-radius: 5px;
  box-shadow: 24;
  padding: 3em;
}

.closeIcon {
  float: right;
  transform: translate(-30%, 30%);
  margin-right: -3em;
  margin-top: -3em;
  cursor: pointer;
  background-color: whitesmoke;
  border: solid thin rgba($primary-color, 0.1);
  color: $primary-color;
}
