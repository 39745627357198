.itemIcon {
  // color: whitesmoke;

  min-width: 40px;
}

.itemIconSvg {
  font-size: 2em;
}

// .itemText{

// }
