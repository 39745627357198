@import '../../assets/design/colors';
.headerTypo {
  font-size: medium;
  font-weight: bold;
  color: $primary-color;
  margin: 1em;
}
.formFieldRow {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-right: 20px;
  margin-top: 1em;
  align-items: center;
}

.gridBox {
  width: 100%;
  padding: 2em;
}
.modalBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: justify;
}
.btnBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1em;
}
.modalBtn {
  margin: 2em 1em 0;
}
