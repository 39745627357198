@import '../../assets/design/colors';

%display-col-div {
  display: flex;
  flex-direction: column;
}

%display-col-div-centered {
  @extend %display-col-div;
  align-items: center;
  justify-content: center;
}

%display-row-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.mainBody {
  @extend %display-col-div;
  width: 100%;
  height: 100%;
  // margin-right: 1em;
  // margin-left: auto;
  background-color: whitesmoke;
}

.avatarImg {
  width: 70px;
  height: 70px;
}
.colFlexEndBox {
  @extend %display-col-div;
  justify-content: flex-end;
}
.centeredColBox {
  @extend %display-col-div-centered;
}
.rowBox {
  @extend %display-row-div;
  flex: 1;
}
.doubleRowBox {
  @extend %display-row-div;
  flex: 2;
}
.socialIcon {
  // background-color: rgba(0, 84, 133, 0.1);
  color: $primary-color;
  margin-right: 10px;
}
.contentBox {
  @extend %display-col-div;
  margin: 1em 1em 1em 1em;
  flex: 1;
}
.socialMediaBox {
  @extend %display-col-div;
  margin: 1em 1em 1em 1em;
  padding-top: 2em;
  flex: 1;
}
.avatarBox {
  @extend %display-col-div-centered;
  padding-left: 3em;
  padding-top: 1em;
  width: 200px;
}
.culomnFieldSet {
  @extend %display-col-div;
  width: 100%;
  border: solid thin rgba($primary-color, 0.5);
  border-radius: 5px;
  padding: 2em;
}
.legendFieldSet {
  font-size: small;
  font-weight: bold;
  color: $primary-color;
}
.counterBox {
  @extend %display-col-div-centered;
  margin: 0 1em 2em 1em;
  background-color: rgba($primary-color, 0.1);
  border-radius: 5px;
  flex: 1;
  font-weight: bold;
}
.labelTypo {
  font-weight: bold;
  margin-right: 1em;
}
.counter {
  color: $primary-color;
  font-size: 1.5em;
  margin: 0;
}

.border {
  border: solid thin rgba($primary-color, 0.5);
}
