@import '../../../assets/design/colors';

.appBar {
  z-index: 20;
  border: none;
  background-color: $primary-color;
  height: 50px;
  position: fixed;
  top: 0;
  box-shadow: 1px 1px 4px #888888;
}
.toolBar {
  min-height: 50px;
  padding: 0;
}
.menuIconButton {
  color: whitesmoke;
  margin-left: 0.3em;
}
.iconBtn {
  font-size: 1.2em;
  color: whitesmoke;
  margin-right: 1em;
}

.flagStyle {
  width: 25px;
  border-radius: 50%;
}

.textStyle {
  color: whitesmoke;
  text-transform: capitalize;
}

.personIconBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.personNameBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: cente;
}
