.mainDiv {
  height: 100%;
  width: 100%;
  padding: 1em;
  padding-top: 3em;
  display: flex;
  justify-content: center;
}

.formDiv {
  width: 100%;
  max-width: 350px;
  height: 450px;
  background-color: white;
  border-radius: 10px;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
}

.modalBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: justify;
}
.btnBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1em;
}
.modalBtn {
  margin: 2em 1em 0;
}
.btnRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 1em 25px 1em 0;
}
.formBtn {
  margin: 0.5em;
}
