@tailwind utilities;

* {
  box-sizing: border-box;
}

.dialog-form {
  width: 100%;
  max-width: 900px;
  padding: 1em;
}

@media screen and (min-width: 768px) {
  .dialog-form {
    padding: 2em;
  }
}
