.modalBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: justify;
}
.btnBox {
  display: flex;
  justify-content: flex-end;
  gap: 0.75em;
}
.modalBtn {
  width: 110px;
}

.rowBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.btn {
  margin-left: auto;
  margin-right: 0px;
  height: 3em;
}
