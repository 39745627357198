@import '../../assets/design/colors';

.colOrderedBox {
  display: flex;
  flex-direction: column;
}

// .DialogBox {
//   display: flex;
//   width: 100%;
//   flex-direction: column;
// }

.form {
  max-width: 900px;
  width: 100%;
  padding: 2em;
}
.boxRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.formRow {
  display: flex;
  flex-direction: row;
  margin: 2em;
}
.fieldBox {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 2em;
}
.doubleFieldBox {
  display: flex;
  flex: 2;
  flex-direction: column;
  margin-right: 2em;
}
// .btnBox {
//   display: flex;
//   flex-direction: row;
//   margin: 4em;
//   justify-content: flex-end;
// }
.formBtn {
  min-width: 90px;
  margin-left: 0.5em;
}

.headerTypo {
  font-size: medium;
  font-weight: bold;
  color: $primary-color;
  margin: 1em;
}
.modalBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: justify;
}
.btnBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 1em 0 1em;
}
.modalBtn {
  margin: 2em 0.5em 0;
}
.btn {
  position: absolute;
  right: 5px;
  top: 5px;
  height: 3em;
  font-weight: bold;
  font-size: 1em;
  z-index: 2;
}
