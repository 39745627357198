@import '../../assets/design/colors';

.headerTypo {
  font-size: medium;
  font-weight: bold;
  color: $primary-color;
  margin: 1em;
}
.headerCaption {
  font-size: small;
  font-weight: bold;
  color: $primary-color;
  margin: 1em;
}
.boxRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}
.boxRowFlexEnd {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex: 1;
  margin-top: 0.2em;
  margin-bottom: 1em;
}
.boxRowFlexEnd {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: 1;
  margin-top: 0.2em;
  margin-bottom: 1em;
}
.btn {
  margin-left: auto;
  margin-right: 0px;
  height: 3em;
}
.modalBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: justify;
}
.btnBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 1em;
  padding-bottom: 1em;
}
.modalBtn {
  margin: 2em 0.5em 0;
}
.DialogBox {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.form {
  width: 100%;
  max-width: 900px;
  padding: 2em;
}
.formBtn {
  min-width: 90px;
  margin-left: 0.5em;
  margin-top: 2em;
}

.dialogFiledSet {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1em;
  @media screen and (min-width: 768px) {
    padding: 1em 2em;
  }
}
.linkTypography {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  margin-left: auto;
  margin-right: 5px;
}
.createModalBox {
  width: 100%;
}
