@import url('https://fonts.googleapis.com/css2?family=Freehand&display=swap');

%flexCol {
  display: flex;
  flex-direction: column;
}

%rowFlexBox {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.colFlexPaper {
  @extend %flexCol;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 1em;
}
.fieldDiv {
  flex: 1;
  @extend %flexCol;
  margin-right: 20px;
}
.doubledFieldDiv {
  flex: 2;
  @extend %flexCol;
  margin-right: 20px;
}
.trippledFieldDiv {
  flex: 3;
  @extend %flexCol;
  margin-right: 20px;
}
.colFlexBoxItem {
  flex: 1;
  @extend %flexCol;
}

.coloredColFlexBox {
  @extend %flexCol;
  width: 100%;
  background-color: aliceblue;
  border-radius: 5px;
  padding: 1em;
  margin-bottom: 1em;
  // margin-top: 1em;
}

.paperLogo {
  width: 50px;
  height: 50px;
  margin-left: auto;
  margin-right: 0;
}

.boldTypo {
  font-weight: bold;
}

.formDiv {
  width: 100%;
}

.rowFieldSet {
  @extend %rowFlexBox;
  align-items: flex-start;
  margin-bottom: 1em;
}

.searchRowFieldSet {
  @extend %rowFlexBox;
  align-items: flex-end;
  margin-bottom: 1em;
}

.TitleBox {
  @extend %rowFlexBox;
  margin-top: 1em;
  align-items: flex-start;
}

.textInputButton {
  min-width: 35px;
  width: 35px;
  background-color: rgb(198, 227, 252);
  margin-bottom: 8px;
}

.rowFiledDiv {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.linkTypo {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 5px;
}

.signBtnBox {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}

.submitBtn {
  margin-right: 0;
  margin-left: auto;
}

.modalBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: justify;
}
.btnBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1em;
}
.modalBtn {
  margin: 2em 1em 0;
}

.freehand {
  font-family: 'Freehand', cursive;
  font-size: 1.5rem;
  line-height: 0.875rem;
}
