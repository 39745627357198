@import '../../assets/design/colors';

.mainBox {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  position: relative;
}

.headerBox {
  position: fixed;
  top: 0;
  background-color: $primary-color;
  width: 100%;

  height: 50px;
  @media screen and (min-width: 900px) {
    height: 100px;
  }
}
.footerBox {
  position: fixed;
  bottom: 0;
  height: 30px;
  width: 100%;
  background-color: $primary-color;
}
.logo {
  position: fixed;
  left: 50px;
  z-index: 2;
  width: 100px;
  height: 100px;
  @media screen and (min-width: 900px) {
    width: 140px;
    height: 140px;
  }
}

.photoBox {
  position: fixed;
  width: 100%;
  height: 200px;
  background-image: url('../../assets/img/SHL-Event-2018_2-2160x1440.jpg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  z-index: 1;
  filter: blur(2px);
  top: 50px;
  @media screen and (min-width: 900px) {
    top: 100px;
  }
}

.loginBox {
  background-color: $secondary-color;
  z-index: 2;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  max-width: 350px;
  height: calc(100vh - 155px);
  max-height: 450px;
  margin-top: 120px;
  @media screen and (min-width: 900px) {
    width: 280px;
    max-height: 400px;
    position: -webkit-sticky;
    position: sticky;
    top: 180px;
    right: 100px;
    margin-right: 100px;
    margin-left: auto;
    left: auto;
  }
}

.contentBox {
  position: -webkit-sticky;
  position: sticky;
  top: 180px;
  left: 100px;
  right: auto;
  margin-left: 100px;
  margin-right: auto;
  width: calc(100% - 550px);
  height: 400px;
  background-color: rgba($color: #ffffff, $alpha: 0.9);
  z-index: 2;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width: 900px) {
  .contentBox {
    display: none;
  }
}

.contentBoxInner {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2em 2.5em;
}
.paragraphHeader {
  font-size: large;
  font-weight: bold;
  color: $primary-color;
  margin-bottom: 2em;
}

.loginHeader {
  font-size: large;
  font-weight: bold;
  color: $primary-color;
  margin-bottom: 2em;
  text-transform: uppercase;
}

.paragraphText {
  font-size: 0.9em;
  font-weight: bold;
  margin-bottom: 2em;
}
.contentBtnBox {
  display: flex;
  flex-direction: row;
}
.contentBtn {
  //   background-color: $primary-color;
  //   border-radius: 10px;
  //   width: 200px;
  color: $primary-color;
  text-decoration: underline;
  cursor: pointer;
  //   margin: 0 auto 1em auto;
}

.loginLogoBox {
  display: flex;
  justify-content: flex-end;
  padding-top: 1em;
  padding-right: 1em;
}

.loginRow {
  padding-left: 1em;
  padding-right: 1em;
  margin-bottom: 1em;
}

.txt {
  background-color: white;
}
.loginBtn {
  background-image: linear-gradient(90deg, $violet-color, #5b42f3 50%, $turkis-color);
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #ffffff;
  font-size: 1.1em;
  width: 100%;
  min-width: 140px;
  margin-top: 1em;
  margin-bottom: 1em;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.loginBtn:disabled {
  color: rgb(200, 200, 200);
}

.loginBtn:active,
.loginBtn:hover {
  outline: 0;
}

.loginBoxLinks {
  color: $primary-color;
}

.modalBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modalBtn {
  min-width: 15em;
}

.rowDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1em;
}
