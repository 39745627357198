@import '../../assets/design/colors';
.rowCenterAlignedFieldSet {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1em;
}

.tabsContainer {
  max-width: 900px;
}

.btnRow {
  justify-content: flex-end;
  margin-top: 1em;
}
.btn {
  margin-right: 1em;
  min-width: 110px;
}
.modalBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: justify;
}
.btnBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1em;
}
.modalBtn {
  margin: 2em 1em 0;
}
.socialIcon {
  color: $primary-color;
  margin-right: 10px;
}

.emptyRow {
  display: block;
  margin-top: 2.5em;
}
