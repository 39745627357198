@import '../../assets/design/colors';

.headerTypo {
  font-size: medium;
  font-weight: bold;
  color: $primary-color;
  margin: 1em;
}

.modalBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: justify;
}

.btnBox {
  display: flex;
  justify-content: flex-end;
  gap: 0.75em;
}
.modalBtn {
  min-width: 110px;
}

.formBox {
  display: flex;
  flex-direction: column;
  padding: 3em;
}

.row {
  margin-bottom: 1em;
}
.btnRow {
  display: flex;
  justify-content: flex-end;
}

.btn {
  min-width: 150px;
}
