.rowBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.modalBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: justify;
}
.btnBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.modalBtn {
  margin: 2em 1em 0;
}
