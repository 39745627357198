.formLabel {
  margin-right: 2em;
}

.fieldDiv {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 20px;
}
