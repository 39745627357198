@import '../../assets/design/colors';

.modalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  background-color: whitesmoke;
  border: solid thin $primary-color;
  border-radius: 10px;
  box-shadow: 24;
  padding: 3em;
}

.largeModalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  background-color: whitesmoke;
  border: solid thin $primary-color;
  border-radius: 10px;
  box-shadow: 24;
  padding: 3em;
}

// .closeIcon {
//   float: right;
//   transform: translate(-30%, 30%);
//   margin-right: -3em;
//   margin-top: -3em;
//   cursor: pointer;
//   background-color: $secondary-color;
//   border: solid thin rgba(0, 84, 133, 0.1);
//   color: $primary-color;
// }
