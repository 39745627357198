.loaderBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.message {
  color: gray;
  margin-bottom: 2em;
}

.lockBox {
  width: 100%;
  height: calc(100vh / 1.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
